<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <h1>NFO设维系统</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-menu class="el-menu-demo" router>
          <el-menu-item index="/mreport" class="menu-item"><i class="el-icon-phone" style="font-size: 24px;"></i>我要报修</el-menu-item>
          <el-menu-item index="/mreportlist" class="menu-item">
            <template v-if="reportsum > 0">
              <el-badge :value="reportsum" :max="10" class="badge">
                <i class="el-icon-s-order" style="font-size: 24px;"></i>报修任务
              </el-badge>
            </template>
            <template v-else>
              <i class="el-icon-s-order" style="font-size: 24px;"></i>报修任务
            </template>
          </el-menu-item>
          <el-menu-item index="/mrepairview" class="menu-item"><i class="el-icon-s-order" style="font-size: 24px;"></i>所有工单</el-menu-item>
          <el-menu-item index="/myrepair" class="menu-item"><i class="el-icon-notebook-2" style="font-size: 24px;"></i>我的维修</el-menu-item>
          <el-menu-item index="/myreport" class="menu-item"><i class="el-icon-notebook-1" style="font-size: 24px;"></i>我的报修</el-menu-item>
          <el-menu-item index="/mchangerecord" class="menu-item"><i class="el-icon-notebook-1" style="font-size: 24px;"></i>交接班填写</el-menu-item>
          <el-menu-item v-if="users.role !== 'ROLE_USER'" index="/mcomponent" class="menu-item"><i class="el-icon-setting" style="font-size: 24px;"></i>备件领用入库</el-menu-item>
          <el-menu-item index="/maddvice" class="menu-item"><i class="el-icon-notebook-1" style="font-size: 24px;"></i>改进建议</el-menu-item>
<!--          <el-button type="primary"  @click="test">测试</el-button>-->
        </el-menu>
      </el-main>
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Mobile",
  data() {
    return {
      reportsum: null,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      users: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/repairReport/getreportsum").then(res => {
        // this.tableData = res.data.records
        this.reportsum = res.data
        console.log(this.reportsum)
      })
      this.request.get("/user/"+this.user.id).then(res => {
        this.users = res.data
      })
    },
    test(){
      this.request.post("/wechat/sendTextMsg")
    }
  }

};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.menu-item {
  font-size: 25px; /* 设置菜单栏项的字体大小 */
  margin-top: 25px;
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}

</style>
